<template>
  <v-card>
    <v-card-title>
      {{ $t('UserInformation') }}
    </v-card-title>
    <v-card-subtitle
      v-if="!!userData.is_lock"
      class="error--text py-1"
    >
      <i18n path="UserHasLockedMsg">
        <template #name>
          {{ userData.updated_by_name }}
        </template>
      </i18n>
    </v-card-subtitle>
    <v-card-text>
      <v-tabs
        v-model="activeTab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.routeName"
          :to="{ name: tab.routeName }"
          exact
        >
          {{ $t(tab.title) }}
        </v-tab>
      </v-tabs>

      <router-view></router-view>
    </v-card-text>
  </v-card>
</template>

<script>
import AccountService from '@/services/AccountService'
import { useRouter } from '@core/utils'
import { onMounted, provide, ref } from '@vue/composition-api'

export default {
  setup() {
    const activeTab = 'staff-user-detail'
    const tabs = [
      { title: 'Information', routeName: 'staff-user-detail' },
      { title: 'Family', routeName: 'staff-user-family' },
      { title: 'Role', routeName: 'staff-user-role' },
    ]

    const { router } = useRouter()
    const userId = router.currentRoute.params.id
    const userData = ref({})

    const getUserData = async () => {
      try {
        const resp = await AccountService.getUserProfile({
          id_user: userId,
        })

        userData.value = resp.data.data
      } catch {}
    }

    onMounted(async () => {
      await getUserData()
    })

    provide('userData', userData)
    provide('getUserData', getUserData)

    return {
      tabs,
      activeTab,
      userData,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
  box-shadow: none !important;
}
</style>
